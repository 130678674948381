
import { setupMicro } from '@/plugins'
import { onMounted, nextTick, defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'microM',
    setup () {
        onMounted(() => {
            // nextTick(() => {
            setupMicro()
            // })
        })
        return {

        }
    }
})
