import { defineComponent, onMounted } from 'vue'
import LeftSide from './LeftSide/LeftSide.vue'
import WdHeader from '@/components/WdHeader/WdHeader.vue'
import { setupMicro } from '@/plugins'
export default defineComponent({
    name: 'Micro',
    components: {
        LeftSide,
        WdHeader
    },
    setup () {
        onMounted(() => {
            setupMicro()
        })
        return {

        }
    }
})
