
// 需要过滤不展示的应用
// 营销系统、咨询师助手、新教务系统、教学服务平台、产品管理、教务管理、测评系统、绩效考核
const filterAppIdSet = new Set(['marketing', 'consulthelper', 'eduadm', 'teach', 'product', 'educational', 'evaluation', 'performance'])


// 对指定的应用进行排序
// 系统管理、线索管理、咨询师助手后台、数据统计、 线上资源、电子合同，其余应用无要求
const sortAppIdSet = new Map([['auth', 0], ['student', 1], ['consultsys', 2], ['statistics', 3], ['consult', 4], ['contract', 5]])


export {
    filterAppIdSet,
    sortAppIdSet
}
